import Axios from 'axios'
import { Chart, Company, PivotTable, Report, RootUser, User, UserGroup } from "../interfaces/interfaces"

// export const JIGSAW_DEV_REST_LOCATION = `http://143.244.136.121:8005`
export const JIGSAW_DEV_REST_LOCATION = `https://api-beta.clickmerito.com`
// export const JIGSAW_DEV_REST_LOCATION = `http://localhost:8005`
let LINK = JIGSAW_DEV_REST_LOCATION

class RestService {

    loginUser = (email: string, password: string): Promise<{ user: RootUser, jwt: string }> => {
        return new Promise((resolve, reject) => {
            Axios.post(`${LINK}/RootUsers/Login`, { email, password }).then((res) => {
                if (res.data.success) {
                    resolve({
                        user: res.data.rootUser,
                        jwt: res.data.jwt
                    })
                } else reject(res.data.error)
            }).catch(reject)
        })
    }

    verifyJWT = (jwt: string): Promise<RootUser> => {
        return new Promise((resolve, reject) => {
            Axios.post(`${LINK}/RootUsers/VerifyJWT`, { jwt }).then((res) => {
                if (res.data.success) {
                    resolve(res.data.rootUser)
                } else reject(res.data.error)
            }).catch(reject)
        })
    }

    getAllCompanies = (): Promise<Company[]> => {
        return new Promise((resolve, reject) => {
            Axios.post(`${LINK}/Companies/GetAll`, {}).then((res) => {
                if (res.data.success) {
                    resolve(res.data.companies)
                } else reject(res.data.error)
            }).catch(reject)
        })
    }

    newCompany = (company: Company): Promise<Company> => {
        return new Promise((resolve, reject) => {
            Axios.post(`${LINK}/Companies/New`, { company }).then((res) => {
                if (res.data.success) {
                    resolve(res.data.company)
                } else reject(res.data.error)
            }).catch(reject)
        })
    }

    getAllUsers = (): Promise<User[]> => {
        return new Promise((resolve, reject) => {
            Axios.post(`${LINK}/Users/GetAll`, {}).then((res) => {
                if (res.data.success) {
                    resolve(res.data.users)
                } else reject(res.data.error)
            }).catch(reject)
        })
    }

    getUsersByCompany = (companyId: number): Promise<User[]> => {
        return new Promise((resolve, reject) => {
            Axios.post(`${LINK}/Users/GetUsersByCompany`, { companyId }).then((res) => {
                if (res.data.success) {
                    resolve(res.data.users)
                } else reject(res.data.error)
            }).catch(reject)
        })
    }

    newUser = (user: User, password: string): Promise<User> => {
        return new Promise((resolve, reject) => {
            Axios.post(`${LINK}/Users/New`, { user, password }).then((res) => {
                if (res.data.success) {
                    resolve(res.data.user)
                } else reject(res.data.error)
            }).catch(reject)
        })
    }

    deleteUser = (userId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            Axios.post(`${LINK}/Users/Delete`, { userId }).then((res) => {
                if (res.data.success) {
                    resolve()
                } else reject(res.data.error)
            }).catch(reject)
        })
    }

    deleteCompany = (companyId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            Axios.post(`${LINK}/Companies/Delete`, { companyId }).then((res) => {
                if (res.data.success) {
                    resolve()
                } else reject(res.data.error)
            }).catch(reject)
        })
    }
}

export let RestS = new RestService()
